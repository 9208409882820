export async function getStaticAppSettings(env: string) {
  if (env === "development") {
    return {
      bar: "bar",
      foo: "foo",
      env: "dev"
    }
  }

  const host = window.location.protocol + "//" + window.location.host;
  const response = await fetch(`${host}/settings/env.json`);
  const appEnv = await response.json();

  return appEnv;
}